<template>
  <a-form
    labelAlign="right"
    :label-col="labelCol"
    :model="website"
    style="padding: 50px 80px"
  >
    <a-form-item label="域名">
      <a-input disabled="true" :value="website.domain" />
    </a-form-item>

    <a-form-item label="站点ID">
      <a-input disabled="true" :value="website.id" />
    </a-form-item>

    <a-form-item label="到期时间">
      <a-input disabled="true" :value="website.end_time" />
    </a-form-item>

    <a-form-item label="授权码">
      <a-input :value="website.auth_code" />
    </a-form-item>

    <a-form-item label="状态">
      <a-tag color="#2db7f5" v-if="website.status === 0">未授权</a-tag>
      <a-tag color="#87d068" v-else-if="website.status === 1">授权中</a-tag>
      <a-tag color="#f50" v-else-if="website.status === 2">已到期</a-tag>
      <a-tag color="#1B222D" v-else>未知</a-tag>
    </a-form-item>

    <a-form-item>
      <a-button type="primary" @click="submit()">验证授权</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { Form, FormItem, Input, Button, Tag } from "ant-design-vue/es";

import state from "../../store/state";
import tool from "../../utils/tool";
import system from "../../api/system";

export default {
  name: "Auth",
  components: {
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AButton: Button,
    ATag: Tag,
  },
  data() {
    return {
      website: {},
      labelCol: {
        style: {
          width: "80px",
        },
      },
    };
  },
  created() {
    this.website = state.adminInfo.website;
    if (this.website.status === 0) {
      this.website.end_time = "";
    } else {
      this.website.end_time = tool.formatDate(this.website.end_time);
    }
    this.checkAuth();
  },
  methods: {
    //检查授权
    checkAuth() {
      system
        .checkAuth()
        .then((res) => {
          if (res.code === 0) {
            if (res.msg === "已到期") {
              this.website.status = 2;
            }
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      alert("验证授权");
    },
  },
};
</script>

<style scoped>
.ant-form-item-label {
  width: 80px;
}
</style>
