import request from "../utils/request";

export default {
  checkAuth() {
    return request({
      url: "/system/auth/check",
      method: "get",
    });
  },
  getFiles(data) {
    return request({
      url: "/system/files",
      method: "get",
      data: data,
    });
  },
  deleteFile(website_id, items) {
    return request({
      url: "/system/files",
      method: "delete",
      data: { website_id: website_id, items: items },
    });
  },
};
